import { UserProfile } from '@sunrun/sales-experience-shared';
import { LoadingIndicator } from '@sunrun/experience-ui-components';
import { useUserProfile } from '../../providers/UserProfile';
import { CustomerProfileIFrame } from 'components/organisms/CustomerProfileIFrame';
import { FullscreenBlock } from 'components/atoms/FullscreenBlock';

const CustomerProfile = () => {
  const userProfileData = useUserProfile();
  return <CustomerProfileProtector user={userProfileData} />;
};

const CustomerProfileProtector = ({ user }: { user: UserProfile }) => {
  if (!user.userId) {
    return (
      <FullscreenBlock>
        <LoadingIndicator color="black" height={60} width={60} />
      </FullscreenBlock>
    );
  }
  return <CustomerProfileIFrame />;
};

export { CustomerProfile };
