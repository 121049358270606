import styled from '@emotion/styled';
import { Button } from '@sunrun/experience-ui-components';
import { useState } from 'react';
import { CommandMasquerade } from 'messages';
import { EVENT_BUS } from 'adapters/common';

const Masquerade = () => {
  const [masqueradeContactId, setMasqueradeContactId] = useState('');
  const onClick = () => {
    const message = {
      type: 'COMMAND:MASQUERADE',
      source: 'Auth',
      payload: {
        masqueradeContactId: masqueradeContactId,
      },
    } as CommandMasquerade;
    EVENT_BUS.emit('COMMAND:MASQUERADE', message);
  };
  return (
    <ContainerDiv>
      <div>
        <label>
          Salesforce Contact ID:
          <input
            name="masquerade-contact-id"
            value={masqueradeContactId}
            onChange={(e) => setMasqueradeContactId(e.target.value)}
          />
        </label>
      </div>
      <Button size="sm" onClick={onClick}>
        Masquerade
      </Button>
    </ContainerDiv>
  );
};

const ContainerDiv = styled.div`
  padding: 2rem;
`;

export { Masquerade };
