import styled from '@emotion/styled/macro';
import { NotificationsPage } from 'adapters/config';
import { useNotificationsAdapter } from 'adapters/notifications';

const NotificationsIFrame = ({ page }: { page: NotificationsPage }) => {
  const { basePath, ref, src, title } = useNotificationsAdapter(page);
  return (
    <IFrameContainer
      key={basePath}
      ref={ref}
      src={src}
      title={title}
      data-testid="sidenav-item-notifications"
    />
  );
};

const NotificationsButton = () => {
  return <NotificationsIFrame page="button" />;
};

const IFrameContainer = styled.iframe`
  height: 25px;
  width: 25px;
  border: 0px;
  overflow: hidden;
`;

export { NotificationsButton };
