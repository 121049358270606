import styled from '@emotion/styled/macro';
import {
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
  Button,
} from '@sunrun/experience-ui-components';

const TableauNoAccess = () => {
  return (
    <TableauNoAccessContainer>
      <SvgIcon
        name={SvgNames.IssueTemporary}
        color={tokens.WARNING_20}
        width="80"
        height="80"
      />
      <Typography
        color={tokens.WARNING_20}
        variant="p"
        size={tokens.FONT_SIZE_5}
        lineHeight={30.8}
        style={{
          fontFamily: `${tokens.FONT_FAMILIES_ROOBERT}`,
        }}>
        We weren’t able to access your performance dashboard. Please file a
        ticket with the IT & Service Desk.
      </Typography>
      <Button
        data-testid="sales-experience--SalesMetrics--tableauNoAccess--report--button"
        size="sm"
        color="primary"
        style={{
          width: '151px',
          height: '60px',
          marginTop: '40px',
          fontFamily: `${tokens.FONT_FAMILIES_ROOBERT}`,
        }}
        onClick={() =>
          window.open(
            'https://vivintsolar.service-now.com/help?id=sc_cat_item&sys_id=d42ed9524f858600d59d76601310c76a',
            '_blank',
          )
        }>
        Report Issue
      </Button>
    </TableauNoAccessContainer>
  );
};

const TableauNoAccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 521px;
  height: 305px;
  gap: 16px;
`;

export { TableauNoAccess };
