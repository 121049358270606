import { useCustomerProfileAdapter } from 'adapters/customerProfile';
import { IFrame } from 'components/atoms/IFrame';

const CustomerProfileIFrame = () => {
  const { basePath, ref, src, title } = useCustomerProfileAdapter();
  return (
    <IFrame basePath={basePath} ref={ref} title={title} src={src}></IFrame>
  );
};

export { CustomerProfileIFrame };
