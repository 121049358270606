import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { PropsWithChildren, ReactElement } from 'react';
import { getNewPath } from 'adapters/common';

type NavButtonProps = PropsWithChildren<{
  href: string;
  icon?: () => ReactElement;
  onClick?: () => void;
}>;

const NavButtonContainer = ({
  to,
  onClick,
  children,
}: PropsWithChildren<{ to: string; onClick?: () => void }>) => {
  const path = getNewPath(to);
  return (
    <StyledNavLink to={path} onClick={onClick}>
      {children}
    </StyledNavLink>
  );
};

const NavButton = ({ children, href, icon, onClick }: NavButtonProps) => {
  return (
    <NavButtonContainer to={href} onClick={onClick}>
      <>
        {icon && icon()}
        {children && <NavText>{children}</NavText>}
      </>
    </NavButtonContainer>
  );
};

const StyledNavLink = styled(NavLink)`
  align-items: center;
  color: #fff;
  display: flex;
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
`;

const NavText = styled.div`
  text-transform: capitalize;
  margin-left: 8px;
`;

export { NavButton };
