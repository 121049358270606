import { UserProfile } from '@sunrun/sales-experience-shared';
import {
  AmplifyGet,
  AmplifyPost,
} from '@sunrun/sales-experience-shared/amplify-api-wrapper';
import { logger } from './logger';

class SalesExpApi {
  static apiName = 'SalesExp';
  static getUserProfile(authkey: string): Promise<UserProfile> {
    return AmplifyGet<{ userProfile: UserProfile }>(
      'Get User Profile',
      this.apiName,
      '/getUserProfile',
      {
        headers: {
          Authorization: `Bearer ${authkey}`,
        },
      },
      logger,
    ).then((res) => {
      return res.userProfile?.sfdcContact ?? res.userProfile;
    });
  }
  static tableauLogin(email: string): Promise<any> {
    return AmplifyPost<{ credentials: { token: string } }>(
      'Tableau Login',
      this.apiName,
      '/tableau/login',
      {
        body: { userEmail: email },
      },
      logger,
    );
  }
}

export { SalesExpApi };
