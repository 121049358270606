import { SplatPage } from 'adapters/config';
import { useSplatAdapter } from 'adapters/splat';
import { IFrame } from 'components/atoms/IFrame';

type Props = { page: SplatPage };
const AppointmentIFrame = ({ page }: Props) => {
  const { basePath, ref, src, title } = useSplatAdapter(page);
  return (
    <IFrame basePath={basePath} ref={ref} title={title} src={src}></IFrame>
  );
};

export { AppointmentIFrame };
