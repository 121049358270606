import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import {
  Button,
  LoadingIndicator,
  SvgIcon,
  SvgNames,
  tokens,
} from '@sunrun/experience-ui-components';
import { LegacySalesPlatform } from 'components/organisms/LegacySalesPlatformIFrame';
import { DoorsPayload, Message } from 'messages';
import { FullscreenBlock } from 'components/atoms/FullscreenBlock';

const Doors = () => {
  let [searchParams] = useSearchParams();
  const willRunPrescreen = useMemo(() => {
    const customerFirstName = searchParams.get('customerFirstName');
    return customerFirstName !== null && customerFirstName.length > 0;
  }, [searchParams]);
  const [isRunningPrescreen, setIsLoading] = useState(willRunPrescreen);
  const [doorsError, setDoorsError] = useState<DoorsPayload['error']>();
  const timeoutRef = useRef<number>();
  const messageHandler = useCallback((message: Message | undefined) => {
    if (!message) {
      return;
    }
    if (message.type === 'EVENT:DOORS') {
      setDoorsError(message.payload.error);
      window.clearTimeout(timeoutRef.current);
      setIsLoading(false);
      return;
    }
    return message;
  }, []);

  const goToDoors = useCallback(() => {
    setDoorsError(undefined);
  }, []);

  const returnToTerros = useCallback(() => {
    window.location.href = 'https://app.terros.com/';
  }, []);

  const errorDisplay = useMemo(() => {
    if (!doorsError) {
      return undefined;
    }
    switch (doorsError) {
      case 'PRESCREEN_FAIL':
        return (
          <div>
            <SvgIcon
              color={tokens.ERROR_20}
              width={80}
              height={80}
              name={SvgNames.ErrorOutline}
            />
            <ErrorText data-testid="sales-exp-text-prescreen-error">
              Prescreen failed. Would you like to keep canvassing?
            </ErrorText>
            <ButtonContainer>
              <Button
                customTestId="sales-exp-button-use-doors"
                color="secondary"
                onClick={goToDoors}>
                Use Doors
              </Button>
              <Button
                customTestId="sales-exp-button-return-to-terros"
                color="primary"
                onClick={returnToTerros}>
                Return to Terros
              </Button>
            </ButtonContainer>
          </div>
        );
      case 'PRESCREEN_NOHIT':
        return (
          <div>
            <SvgIcon
              color={tokens.WARNING_20}
              width={80}
              height={80}
              name={SvgNames.WarningOutline}
            />
            <WarningText data-testid="sales-exp-text-prescreen-error">
              Prescreen was a no hit. Would you like to keep canvassing?
            </WarningText>
            <ButtonContainer>
              <Button
                customTestId="sales-exp-button-return-to-terros"
                color="secondary"
                onClick={returnToTerros}>
                Return to Terros
              </Button>
              <Button
                customTestId="sales-exp-button-use-doors"
                color="primary"
                onClick={goToDoors}>
                Use Doors
              </Button>
            </ButtonContainer>
          </div>
        );
      case 'MISMATCH':
        return (
          <div>
            <SvgIcon
              color={tokens.ERROR_20}
              width={80}
              height={80}
              name={SvgNames.ErrorOutline}
            />
            <ErrorText data-testid="sales-exp-text-prescreen-error">
              We were unable to process the pre-screen request using Terros'
              resident data.
              <br />
              Would you like to pre-screen in Doors?
            </ErrorText>
            <ButtonContainer>
              <Button
                customTestId="sales-exp-button-return-to-terros"
                color="secondary"
                onClick={returnToTerros}>
                Return to Terros
              </Button>
              <Button
                customTestId="sales-exp-button-use-doors"
                color="primary"
                onClick={goToDoors}>
                Use Doors
              </Button>
            </ButtonContainer>
          </div>
        );
      default:
        return (
          <div>
            <SvgIcon
              color={tokens.ERROR_20}
              width={80}
              height={80}
              name={SvgNames.ErrorOutline}
            />
            <ErrorText data-testid="sales-exp-text-prescreen-error">
              Something went wrong.
              <br />
              Would you like to try again?
            </ErrorText>
            <ButtonContainer>
              <Button
                customTestId="sales-exp-button-return-to-terros"
                color="secondary"
                onClick={returnToTerros}>
                Return to Terros
              </Button>
              <Button
                customTestId="sales-exp-button-use-doors"
                color="primary"
                onClick={goToDoors}>
                Use Doors
              </Button>
            </ButtonContainer>
          </div>
        );
    }
  }, [doorsError, goToDoors, returnToTerros]);

  // If we expect the prescreen to run - make sure the user never sits on an infinite load
  useEffect(() => {
    if (willRunPrescreen) {
      timeoutRef.current = window.setTimeout(() => {
        setDoorsError((currentError) =>
          currentError ? currentError : 'UNKNOWN',
        );
        setIsLoading(false);
      }, 30_000);
    }
    // Intentionally blank to run one-time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DoorsContainer>
      <LegacySalesPlatform page="doors" localMessageHandler={messageHandler} />
      {isRunningPrescreen && (
        <FullscreenBlock>
          <LoadingIndicator
            color="black"
            message="One moment while we process the pre-screen"></LoadingIndicator>
        </FullscreenBlock>
      )}
      {errorDisplay && <FullscreenBlock>{errorDisplay}</FullscreenBlock>}
    </DoorsContainer>
  );
};

const DoorsContainer = styled.div`
  position: relative;
  z-index: 10;

  > * {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const TextFormat = styled.div`
  max-width: 521px;
  margin-top: 1rem;
  line-height: ${tokens.LINE_HEIGHTS_14}px;
  font-family: Roobert, sans-serif;
  font-size: ${tokens.FONT_SIZE_5}px;
`;

const ErrorText = styled(TextFormat)`
  color: ${tokens.ERROR_20};
`;

const WarningText = styled(TextFormat)`
  color: ${tokens.WARNING_20};
`;

const ButtonContainer = styled.div`
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

export { Doors };
