import { ComponentProps, useRef } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useIframeMessages, useSynchronizeUrl } from './common';
import { NotificationsPage, getConfig } from './config';
import type { IFrame } from 'components/atoms/IFrame';

const useNotificationsAdapter = (
  page: NotificationsPage,
): ComponentProps<typeof IFrame> => {
  let [searchParams] = useSearchParams();
  const ref = useRef<HTMLIFrameElement | null>(null);
  const config = getConfig('notifications', page);
  const basePath = config.iFrameUrl;
  const match = useMatch(`/${config.containerPath}/*`);
  const src = `${basePath}/${match?.params['*'] ?? ''}?${searchParams}`;
  const { staticSrc } = useSynchronizeUrl(ref, src, basePath);
  useIframeMessages(ref, 'Notifications', config.containerPath);
  return {
    ref,
    basePath: basePath,
    src: staticSrc,
    title: config.title,
  };
};

export { useNotificationsAdapter };
