import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { useLeadsAdapter } from 'adapters/leads';
import { IFrame } from 'components/atoms/IFrame';
import { CommandUrlChange, Message } from 'messages/Message';
import { postMessage } from 'adapters/helpers';
import { LeadsPage } from 'adapters/config';
import { useHandleCommandUrlChange } from 'adapters/common';

const LeadsIFrame = ({ page }: { page: LeadsPage }) => {
  const [isDirty, setIsDirty] = useState(false);
  const blocker = useBlocker(isDirty);
  const [navigateMessage, setNavigateMessage] = useState<
    CommandUrlChange | undefined
  >(undefined);

  const handleCommandChangeUrl = useHandleCommandUrlChange();
  const messageHandler = useCallback(
    (message: Message | undefined) => {
      if (!message) {
        return;
      }
      if (message.type === 'COMMAND:NAV_PROCEED') {
        blocker?.proceed?.();
        return;
      } else if (message.type === 'COMMAND:NAV_CANCEL') {
        blocker?.reset?.();
        return;
      } else if (message.type === 'COMMAND:CHANGE_DIRTY') {
        setIsDirty(message.payload.isDirty);
        return;
      } else if (message.type === 'COMMAND:CHANGE_URL') {
        setNavigateMessage(message);
        return;
      }
      return message;
    },
    [setIsDirty, blocker, setNavigateMessage],
  );

  // Use a custom handler for change url so that when the url is actually changed,
  //  the blocker is the correct sate (because this will always happen after this component renders)
  useEffect(() => {
    if (navigateMessage) {
      handleCommandChangeUrl(navigateMessage);
    }
  }, [handleCommandChangeUrl, navigateMessage]);

  const { basePath, ref, src, title } = useLeadsAdapter(page, messageHandler);
  useEffect(() => {
    if (blocker.state === 'blocked') {
      postMessage(
        {
          type: 'COMMAND:SHOW_NAVIGATION_BLOCK',
          source: 'Container',
        },
        ref as MutableRefObject<HTMLIFrameElement>,
      );
    }
  }, [ref, blocker.state]);

  const warningText =
    'You have unsaved changes - are you sure you wish to leave this page?';
  const handleWindowClose = useCallback(
    (e: BeforeUnloadEvent) => {
      if (!isDirty) return;
      e.preventDefault();
      return (e.returnValue = warningText);
    },
    [isDirty],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [handleWindowClose]);

  return (
    <IFrame basePath={basePath} ref={ref} title={title} src={src}></IFrame>
  );
};

export { LeadsIFrame };
