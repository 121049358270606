import { Env, getEnv } from 'helpers/env';

type MFE =
  | 'appointment'
  | 'auth'
  | 'customerProfile'
  | 'dashboard'
  | 'leads'
  | 'leagueLive'
  | 'notifications'
  | 'profile'
  | 'splat'
  | 'support'
  | 'salesMetrics'
  | 'analytics';
type DashboardPage = 'overview' | 'pipeline';
type LeadsPage = 'create' | 'view' | 'channelSelection';
type NotificationsPage = 'list' | 'button';
type SplatPage = 'customers' | 'appointments' | 'doors' | 'scheduleAppointment';
type Page =
  | 'appointment'
  | 'login'
  | 'customerProfile'
  | 'leagueLive'
  | 'profile'
  | 'support'
  | 'salesMetrics'
  | 'analytics'
  | DashboardPage
  | LeadsPage
  | NotificationsPage
  | SplatPage;

type PageConfig = { containerPath: string; title: string; iFrameUrl: string };
type MFEConfig = {
  origin: Record<Env, string>;
  pages: Partial<Record<Page, PageConfig>>;
};

const CONFIG: Record<MFE, MFEConfig> = {
  auth: {
    origin: {
      production: 'https://auth.sunrunone.com',
      staging: 'https://auth.staging.sunrunone.com',
    },
    pages: {
      login: {
        containerPath: '/',
        title: 'Login',
        iFrameUrl: '',
      },
    },
  },
  customerProfile: {
    origin: {
      production: 'https://customer-profile.sunrunone.com',
      staging: 'https://customer-profile.staging.sunrunone.com',
    },
    pages: {
      customerProfile: {
        containerPath: '/customers/customer-profile',
        title: 'Customer Profile',
        iFrameUrl: '',
      },
    },
  },
  appointment: {
    origin: {
      production: 'https://appointments.sunrunone.com',
      staging: 'https://appointments.staging.sunrunone.com',
    },
    pages: {
      appointment: {
        containerPath: '/appointment',
        title: 'Create Appointment',
        iFrameUrl: '',
      },
    },
  },
  dashboard: {
    origin: {
      production: 'https://dashboard.sunrunone.com',
      staging: 'https://dashboard.staging.sunrunone.com',
    },
    pages: {
      overview: {
        containerPath: '/dashboard',
        title: 'Dashboard',
        iFrameUrl: '/overview',
      },
      pipeline: {
        containerPath: '/pipeline',
        title: 'Pipeline',
        iFrameUrl: '/pipeline',
      },
    },
  },
  leads: {
    origin: {
      production: 'https://leads.sunrunone.com',
      staging: 'https://leads.staging.sunrunone.com',
    },
    pages: {
      channelSelection: {
        containerPath: '/newLeads/channel-selection',
        title: 'Channel Selection',
        iFrameUrl: '/channel-selection',
      },
      create: {
        containerPath: '/customers/create',
        title: 'Create Customer',
        iFrameUrl: '/create',
      },
      view: {
        containerPath: '/customers/prospects',
        title: 'View Customer',
        iFrameUrl: '/prospects',
      },
    },
  },
  leagueLive: {
    origin: {
      production: 'https://league-live.sunrun.com',
      staging: 'https://majstg-league-live.sunrun.com',
    },
    pages: {
      leagueLive: {
        containerPath: '/performance',
        title: 'League Live',
        iFrameUrl: '',
      },
    },
  },
  notifications: {
    origin: {
      production: 'https://notifications.sunrunone.com',
      staging: 'https://notifications.staging.sunrunone.com',
    },
    pages: {
      list: {
        containerPath: '/list',
        title: 'List',
        iFrameUrl: '/list',
      },
      button: {
        containerPath: '/button',
        title: 'Button',
        iFrameUrl: '/button',
      },
    },
  },
  profile: {
    origin: {
      production: 'https://profile.sunrunone.com',
      staging: 'https://profile.staging.sunrunone.com',
    },
    pages: {
      profile: {
        containerPath: '/profile',
        title: 'Profile',
        iFrameUrl: '',
      },
    },
  },
  splat: {
    origin: {
      staging: 'https://majstg-go.sunrun.com/one',
      production: 'https://go.sunrun.com/one',
    },
    pages: {
      appointments: {
        containerPath: '/appointments',
        title: 'Appointments',
        iFrameUrl: '/appointments',
      },
      customers: {
        containerPath: '/customers',
        title: 'Customers',
        iFrameUrl: '/customers',
      },
      doors: {
        containerPath: '/doors',
        title: 'Doors',
        iFrameUrl: '/doors',
      },
      scheduleAppointment: {
        containerPath: '/schedule-appointment',
        title: 'Schedule Appointment',
        iFrameUrl: '/schedule-appointment',
      },
    },
  },
  support: {
    origin: {
      production: 'https://support.sunrunone.com',
      staging: 'https://support.staging.sunrunone.com',
    },
    pages: {
      support: {
        containerPath: '/support',
        title: 'Support',
        iFrameUrl: '',
      },
    },
  },
  salesMetrics: {
    origin: {
      production:
        'https://10ay.online.tableau.com/t/sunrunbi/views/EmbeddedLandingPageTest/Dashboard1',
      staging:
        'https://10ay.online.tableau.com/t/sunrunbi/views/EmbeddedLandingPageTest/Dashboard1', //Change if we do get a staging url
    },
    pages: {
      salesMetrics: {
        containerPath: '/metrics',
        title: 'Sales Metrics',
        iFrameUrl: '',
      },
    },
  },
  analytics: {
    origin: {
      production: 'https://main.d1aiq1n3xq36bt.amplifyapp.com/',
      staging: 'https://main.d1aiq1n3xq36bt.amplifyapp.com/', //Change if we do get a staging url
      // staging: 'http://localhost:3000/', // uncomment when running locally
    },
    pages: {
      analytics: {
        containerPath: '/analytics',
        title: 'Analytics',
        iFrameUrl: '',
      },
    },
  },
};

function getConfig(mfe: MFE, page: Page): PageConfig {
  const env = getEnv();
  const baseUrl = CONFIG[mfe].origin[env];
  const pageConfig = CONFIG[mfe].pages[page];
  if (!pageConfig) {
    throw new Error('Page ' + page + ' is not configured in MFE ' + mfe);
  }
  const config = { ...pageConfig };
  config.iFrameUrl = baseUrl + config.iFrameUrl;
  return config;
}

export type {
  MFE,
  DashboardPage,
  LeadsPage,
  NotificationsPage,
  SplatPage,
  Page,
};
export { CONFIG, getConfig };
