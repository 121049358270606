import { useState, useEffect } from 'react';
import { SalesExpApi } from './Api';
import { useUserProfile } from './UserProfile';
import { getEnv } from 'helpers/env';

const useTableauLogin = (optionalTestEmail: string | null) => {
  const env = getEnv();
  const [tableauLoginResult, setTableauLoginResult] = useState('Loading...');
  const userProfileData = useUserProfile();
  useEffect(() => {
    if (userProfileData.email) {
      SalesExpApi.tableauLogin(
        env === 'staging' &&
          optionalTestEmail !== null &&
          optionalTestEmail.length > 0
          ? optionalTestEmail
          : userProfileData.email,
      )
        .then((res) => {
          if (res.credentials) {
            setTableauLoginResult(res.credentials.token);
          } else {
            setTableauLoginResult(res);
          }
        })
        .catch(() => {
          // TODO: Fix under SPLAT1-6941
          // Do Nothing
        });
    }
  }, [userProfileData, optionalTestEmail, env]);

  return tableauLoginResult;
};

export { useTableauLogin };
