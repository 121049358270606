import { ComponentProps, useRef } from 'react';
import { useIframeMessages } from './common';
import { getConfig } from './config';
import type { IFrame } from 'components/atoms/IFrame';

const useProfileAdapter = (): ComponentProps<typeof IFrame> => {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const config = getConfig('profile', 'profile');
  const src = config.iFrameUrl;
  useIframeMessages(ref, 'Profile', config.containerPath);
  return {
    ref,
    basePath: src,
    src,
    title: config.title,
  };
};

export { useProfileAdapter };
