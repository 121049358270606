import Rollbar from 'rollbar';
import { getEnv } from '../helpers/env';

const rollbar = new Rollbar({
  accessToken: 'd01f9d21482b4c1ca5cf5b22d6360442',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: getEnv(),
    // context: 'rollbar/test'
    client: {
      javascript: {
        // code_version: '1.0',
        // source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },

    // Adding info about the user affected by this event (optional)
    // The 'id' field is required, anything else is optional
    // person: {
    //   id: '1234',
    //   username: 'testuser',
    //   email: 'user@email',
    // },

    // Example of adding arbitrary metadata (optional)
    // custom: {
    //   trace_id: 'aabbccddeeff',
    //   feature_flag: ['feature_1', 'feature_2'],
    // },
  },
});

export { rollbar };
