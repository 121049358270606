import type { Message } from './Message';

const logMessage = (message: Message) => {
  switch (message.type) {
    case 'COMMAND:CREATE_LEAD':
    case 'COMMAND:CHANGE_URL':
    case 'COMMAND:CHANGE_DIRTY':
    case 'COMMAND:NAV_CANCEL':
    case 'COMMAND:NAV_PROCEED':
    case 'COMMAND:SHOW_NAVIGATION_BLOCK':
    case 'COMMAND:LOGOUT':
    case 'COMMAND:MASQUERADE':
    case 'EVENT:AUTH_TOKEN':
    case 'EVENT:BOOTSTRAP':
    case 'EVENT:DOORS':
    case 'EVENT:READY':
    case 'QUERY:AUTH_TOKEN':
    case 'COMMAND:SHOW_SNACKBAR':
      // eslint-disable-next-line no-console
      console.log(
        '📬',
        message.source,
        message.type,
        'payload' in message ? message.payload : undefined,
      );
      break;
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const never: never = message;
    }
  }
};

export { logMessage };
