import styled from '@emotion/styled/macro';
import { tokens } from '@sunrun/experience-ui-components';
import { ReactElement } from 'react';

type FullscreenBlockPropTypes = {
  children: ReactElement;
};

const FullscreenBlock = ({ children }: FullscreenBlockPropTypes) => {
  return <FullscreenBlockElement>{children}</FullscreenBlockElement>;
};

const FullscreenBlockElement = styled.div`
  background: ${tokens.SURFACE_LIGHT};
  background: linear-gradient(
    23deg,
    ${tokens.SURFACE_LIGHT} 50%,
    color-mix(in srgb, ${tokens.SURFACE_LIGHT} 98.5%, black) 0
  );
  right: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

export { FullscreenBlock };
