import {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { EVENT_BUS, useIframeMessages } from './common';
import { postMessage } from './helpers';
import { getConfig } from './config';
import type { IFrame } from 'components/atoms/IFrame';
import { Message } from 'messages';

type UseAuthAdapterReturn = ComponentProps<typeof IFrame> & {
  isLoaded: boolean;
  showAuth: boolean;
  isOktaToken: boolean;
};
const useAuthAdapter = (): UseAuthAdapterReturn => {
  const [showAuth, setShowAuth] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOktaToken, setIsOktaToken] = useState(false);
  const ref = useRef<HTMLIFrameElement | null>(null);
  const config = getConfig('auth', 'login');
  const src = `${config.iFrameUrl}`;
  const busQueryAuthTokenHandler = useCallback(
    (message: Message) => {
      postMessage(message, ref);
      setShowAuth(true);
    },
    [setShowAuth],
  );
  const busCommandLogoutHandler = useCallback(
    (message: Message) => {
      postMessage(message, ref);
      setShowAuth(true);
      setIsOktaToken(false);
    },
    [setShowAuth],
  );
  const busCommandMasqueradeHandler = useCallback((message: Message) => {
    postMessage(message, ref);
  }, []);
  useEffect(() => {
    EVENT_BUS.on('QUERY:AUTH_TOKEN', busQueryAuthTokenHandler);
    EVENT_BUS.on('COMMAND:LOGOUT', busCommandLogoutHandler);
    EVENT_BUS.on('COMMAND:MASQUERADE', busCommandMasqueradeHandler);
    return () => {
      EVENT_BUS.off('QUERY:AUTH_TOKEN', busQueryAuthTokenHandler);
      EVENT_BUS.off('COMMAND:LOGOUT', busCommandLogoutHandler);
      EVENT_BUS.off('COMMAND:MASQUERADE', busCommandMasqueradeHandler);
    };
  }, [
    busCommandLogoutHandler,
    busQueryAuthTokenHandler,
    busCommandMasqueradeHandler,
  ]);
  useIframeMessages(ref, 'Auth', '/', (message) => {
    if (!message) return;
    switch (message.type) {
      case 'EVENT:AUTH_TOKEN':
        setShowAuth(false);
        setIsOktaToken(message.payload.tokenType === 'okta');
        break;
      case 'EVENT:READY':
        busQueryAuthTokenHandler({
          source: 'Container',
          type: 'QUERY:AUTH_TOKEN',
        });
        setIsLoaded(true);
        break;
    }
    return message;
  });
  return {
    ref,
    basePath: src,
    isLoaded,
    src,
    showAuth,
    isOktaToken,
    title: config.title,
  };
};

export { useAuthAdapter };
