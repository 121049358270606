import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getSplatUserType,
  isRetailUser,
} from '@sunrun/sales-experience-shared';
import { useAuthkey } from '../../providers/Auth';
import { useUserProfile } from 'providers/UserProfile';
import { getNewPath } from 'adapters/common';

const localStorageKey = 'previousAuthkey';

const ChannelSelectionDirector = () => {
  const navigate = useNavigate();
  const userProfileData = useUserProfile();
  const userType =
    JSON.stringify(userProfileData) !== '{}'
      ? getSplatUserType(userProfileData)
      : '';
  const isRetail = userType && isRetailUser(userType);
  const authkey = useAuthkey();

  useEffect(() => {
    const previousAuthkey = localStorage.getItem(localStorageKey);

    if (authkey !== previousAuthkey && isRetail && authkey !== '') {
      localStorage.setItem(localStorageKey, authkey);
      navigate(getNewPath('/newLeads/channel-selection'));
    }
  }, [authkey, userProfileData, navigate, isRetail]);

  return null;
};

export { ChannelSelectionDirector };
