import { SplatPage } from 'adapters/config';
import { useSplatAdapter } from 'adapters/splat';
import { IFrame } from 'components/atoms/IFrame';
import { Message } from 'messages';

type Props = {
  page: SplatPage;
  localMessageHandler?: (message: Message | undefined) => Message | undefined;
};
const LegacySalesPlatform = ({ page, localMessageHandler }: Props) => {
  const { basePath, ref, src, title } = useSplatAdapter(
    page,
    localMessageHandler,
  );
  return (
    <IFrame basePath={basePath} ref={ref} title={title} src={src}></IFrame>
  );
};

export { LegacySalesPlatform };
