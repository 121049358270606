import React, { useCallback, useEffect } from 'react';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { useUserProfile } from './UserProfile';
import { getEnv } from 'helpers/env';

const FlagsmithEnvProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const env = getEnv();
  const userProfileData = useUserProfile();
  const identity = userProfileData.contactId;

  const setIdentity = useCallback((identityToSet: string) => {
    if (!flagsmith.initialised || flagsmith.loadingState?.isFetching) {
      window.setTimeout(() => {
        setIdentity(identityToSet);
      }, 100);
    } else {
      flagsmith.identify(identityToSet);
    }
  }, []);

  useEffect(() => {
    if (identity) {
      setIdentity(identity);
    }
  }, [identity, setIdentity]);

  return (
    <FlagsmithProvider
      options={{
        environmentID:
          env === 'production'
            ? 'eyZgnuwbveRVcUsUC5PDre'
            : 'QL5PWdxYnNQ4DdSJLusfqA',
      }}
      flagsmith={flagsmith}>
      <>{children}</>
    </FlagsmithProvider>
  );
};

export { FlagsmithEnvProvider };
