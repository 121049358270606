import { ReactElement, useEffect } from 'react';
import { Global, css } from '@emotion/react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import { CONFIG, MFE } from 'adapters/config';
import { FlagsmithEnvProvider } from 'providers/Flagsmith';

const App = (): ReactElement => {
  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    for (const mfe in CONFIG) {
      const injectedUrl = params.get(mfe);
      if (injectedUrl) {
        CONFIG[mfe as MFE].origin.staging = injectedUrl;
      }
    }
  });
  return (
    <>
      <Global
        styles={css`
          body {
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
              'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
              'Helvetica Neue', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          code {
            font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
              monospace;
          }

          * {
            box-sizing: border-box;
          }
        `}
      />
      <FlagsmithEnvProvider>
        <RouterProvider router={router} />
      </FlagsmithEnvProvider>
    </>
  );
};

export { App };
