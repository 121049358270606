import { NotificationsPage } from 'adapters/config';
import { useNotificationsAdapter } from 'adapters/notifications';
import { IFrame } from 'components/atoms/IFrame';

const NotificationsIFrame = ({ page }: { page: NotificationsPage }) => {
  const { basePath, ref, src, title } = useNotificationsAdapter(page);
  return (
    <IFrame basePath={basePath} ref={ref} title={title} src={src}></IFrame>
  );
};

export { NotificationsIFrame };
