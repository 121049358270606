import { Md5 } from 'ts-md5/dist/md5';
import { Page } from 'adapters/config';
import { useAnalyticsAdapter } from 'adapters/analytics';
import { IFrame } from 'components/atoms/IFrame';
import { useUserProfile } from 'providers/UserProfile';

type Props = { page: Page };
const AnalyticsIFrame = ({ page }: Props) => {
  const userProfileData = useUserProfile();
  const { basePath, ref, src, title } = useAnalyticsAdapter(page);

  let email = userProfileData.email;

  // Override testing email to a valid email to assess analytics dashboard on staging
  if (email === 'possalesuserl1sr@yopmail.com') {
    email = 'tyler.williams@sunrun.com';
  }

  // Hash the email and token, which is reconstructed in ai-sales-app to validate for security
  const hashVal = Md5.hashStr(email + 'c6c3280fc2184ae08222310bc16fd3f9');
  const queryString = `hash_token=${hashVal}&email=${email}`;
  const baseUrl = `${basePath}?${queryString}`;
  const srcUrl = `${src}?${queryString}`;

  return (
    <IFrame basePath={baseUrl} ref={ref} title={title} src={srcUrl}></IFrame>
  );
};

export { AnalyticsIFrame };
