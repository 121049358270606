import styled from '@emotion/styled/macro';
import { ReactElement } from 'react';

type sizes = 'extra-small' | 'small' | 'medium' | 'large';

type IconProps = {
  src?: string;
  muiIcon?: ReactElement | undefined;
  alt: string;
  size?: sizes;
  width?: string;
  height?: string;
  testId?: string;
};

const Icon = ({
  src,
  muiIcon,
  alt,
  size,
  width = getDimensions(size).width,
  height = getDimensions(size).height,
  testId,
}: IconProps) => {
  return muiIcon ? (
    <MuiIconContainer width={width} height={height}>
      {muiIcon}
    </MuiIconContainer>
  ) : (
    <IconContainer
      width={width}
      height={height}
      src={src}
      alt={alt}
      data-testid={testId}
    />
  );
};

const getDimensions = (
  size: sizes = 'small',
): { width: string; height: string } => {
  switch (size) {
    case 'extra-small':
      return { width: '25px', height: '25px' };
    case 'small':
      return { width: '35px', height: '35px' };
    case 'medium':
      return { width: '50px', height: '50px' };
    case 'large':
      return { width: '75px', height: '75px' };
    default:
      return { width: '50px', height: '50px' };
  }
};

const MuiIconContainer = styled.div<{ width: string; height: string }>`
  .MuiSvgIcon-root {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    color: white;

    &:hover {
      opacity: 0.5;
    }
  }
`;

const IconContainer = styled.img<{ width: string; height: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: white;

  &:hover {
    opacity: 0.5;
  }
`;

export { Icon };
