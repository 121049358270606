import { useProfileAdapter } from 'adapters/profile';
import { IFrame } from 'components/atoms/IFrame';

const ProfileIframe = () => {
  const { basePath, ref, src, title } = useProfileAdapter();
  return (
    <IFrame basePath={basePath} ref={ref} title={title} src={src}></IFrame>
  );
};

export { ProfileIframe };
