import { useEffect, useState } from 'react';
import { Snackbar as GenericSnackbar } from '@sunrun/experience-ui-components';
import { Message } from 'messages';

const Snackbar = () => {
  const [isOpen, setOpen] = useState(false);
  const [toast, setToast] = useState<SnackbarPayload>();

  const handler = (event: MessageEvent<Message | undefined>) => {
    const { data: message } = event;
    if (!message) return;

    switch (message.type) {
      case 'COMMAND:SHOW_SNACKBAR':
        setOpen(true);
        setToast(message.payload);
        break;
      default:
        return message;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
    setToast(undefined);
  };

  return (
    <GenericSnackbar
      message={toast?.message ?? ''}
      open={isOpen}
      autoHideDuration={toast?.autoHideDuration}
      onClose={handleClose}
      type={toast?.type}
    />
  );
};

type SnackbarPayload = {
  type: 'success' | 'error' | 'warning' | 'info';
  message: string;
  autoHideDuration?: number;
};

export { Snackbar };
