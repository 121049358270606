import { useSupportAdapter } from 'adapters/support';
import { IFrame } from 'components/atoms/IFrame';

const SupportIframe = () => {
  const { basePath, ref, src, title } = useSupportAdapter();
  return (
    <IFrame basePath={basePath} ref={ref} title={title} src={src}></IFrame>
  );
};

export { SupportIframe };
