import { useLeagueLiveAdapter } from 'adapters/leagueLive';
import { IFrame } from 'components/atoms/IFrame';

const LeagueLive = () => {
  const { basePath, ref, src, title } = useLeagueLiveAdapter();
  return (
    <IFrame basePath={basePath} ref={ref} title={title} src={src}></IFrame>
  );
};

export { LeagueLive };
