import { Logger, rollbarTransport } from '@sunrun/sales-experience-shared';
import { rollbar } from './rollbar';

const logger = new Logger({
  runtime: 'web',
  application: 'SalesExp',
  transports: [rollbarTransport(rollbar)],
});

export { logger };
