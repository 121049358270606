import { useState, useEffect } from 'react';
import { UserProfile } from '@sunrun/sales-experience-shared';
import { singletonHook } from 'react-singleton-hook';
import { SalesExpApi } from './Api';
import { useAuthkey } from './Auth';
import { rollbar } from './rollbar';
import { identify as identifyFullStoryUser } from './FullStory';

const useUserProfileImpl = () => {
  const [userProfile, setUserProfile] = useState({} as UserProfile);
  const authkey = useAuthkey();
  useEffect(() => {
    if (authkey) {
      SalesExpApi.getUserProfile(authkey)
        .then((res) => {
          identifyFullStoryUser(res.email);
          rollbar.configure({
            payload: {
              profile: {
                id: res.email,
                email: res.email,
              },
              userProfile: {
                ...res,
              },
            },
          });
          setUserProfile({
            ...res,
          });
        })
        .catch(() => {
          // TODO: Fix under SPLAT1-6940
          // Do nothing
        });
    }
  }, [authkey]);
  return userProfile;
};

const useUserProfile = singletonHook({} as UserProfile, useUserProfileImpl);

export { useUserProfile };
