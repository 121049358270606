import styled from '@emotion/styled/macro';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { maxWidth } from 'helpers/styleConstants';

const StyledPageWrapper = styled.div<{ hideNav: boolean }>`
  width: 100%;
  padding-left: ${(props) => (props.hideNav ? '0px' : '80px')};
  @media only screen and (max-width: ${maxWidth}px) {
    padding-left: 0px;
  }
`;

const PageWrapper = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();
  const hideNav = pathname === '/customers/create';
  return <StyledPageWrapper hideNav={hideNav}>{children}</StyledPageWrapper>;
};

export { PageWrapper };
