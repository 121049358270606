import { MutableRefObject } from 'react';
import { Message } from 'messages';

const SUBSCRIBERS: Set<MutableRefObject<HTMLIFrameElement>> = new Set();

const addSubscriber = (ref: MutableRefObject<HTMLIFrameElement | null>) =>
  SUBSCRIBERS.add(ref as MutableRefObject<HTMLIFrameElement>);

const deleteSubscriber = (ref: MutableRefObject<HTMLIFrameElement | null>) =>
  SUBSCRIBERS.delete(ref as MutableRefObject<HTMLIFrameElement>);

const postMessage = <M extends Message>(
  message: M,
  ref: MutableRefObject<HTMLIFrameElement | null>,
) => ref.current?.contentWindow?.postMessage(message, '*');

const broadcastMessage = <M extends Message>(message: M) => {
  for (const subscriber of SUBSCRIBERS.values()) {
    postMessage(message, subscriber);
  }
};

export { addSubscriber, broadcastMessage, deleteSubscriber, postMessage };
