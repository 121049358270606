import styled from '@emotion/styled/macro';
import { forwardRef } from 'react';
import { maxWidth } from '../../helpers/styleConstants';

type IFrameProps = {
  basePath: string;
  src: string;
  title: string;
};

const IFrame = forwardRef<HTMLIFrameElement, Omit<IFrameProps, 'ref'>>(
  ({ basePath, src, title }, ref) => {
    return (
      <IFrameContainer
        key={basePath}
        ref={ref}
        src={src}
        title={title}
        allow="geolocation; fullscreen; clipboard-write;"
      />
    );
  },
);

const IFrameContainer = styled.iframe`
  width: 100%;
  height: calc(100vh - 75px); // deduct height of mobile nav
  border: 0px;
  flex-grow: 1;
  display: block;

  @media (min-width: ${maxWidth + 1}px) {
    height: 100vh;
  }
`;

export { IFrame };
