import { UserProfile } from '@sunrun/sales-experience-shared';
import { LoadingIndicator } from '@sunrun/experience-ui-components';
import { useUserProfile } from '../../providers/UserProfile';
import { OneAppointmentIFrame } from 'components/organisms/OneAppointmentIFrame';
import { FullscreenBlock } from 'components/atoms/FullscreenBlock';

const OneAppointment = () => {
  const userProfileData = useUserProfile();
  return <AppointmentProtector user={userProfileData} />;
};

const AppointmentProtector = ({ user }: { user: UserProfile }) => {
  if (!user.userId) {
    return (
      <FullscreenBlock>
        <LoadingIndicator color="black" height={60} width={60} />
      </FullscreenBlock>
    );
  }
  return <OneAppointmentIFrame />;
};

export { OneAppointment };
