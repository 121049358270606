import { EventEmitter } from 'events';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommandUrlChange, logMessage, Message } from '../messages';
import {
  addSubscriber,
  broadcastMessage,
  deleteSubscriber,
  postMessage,
} from './helpers';
import { CONFIG } from './config';

const EVENT_BUS = new EventEmitter();

const unwrapAndFilterEvent = (
  event: MessageEvent<Message>,
  source: Message['source'],
): Message | undefined => {
  const { data: message } = event;
  if (!message || message.source !== source) {
    return;
  }
  return message;
};

const getNewPath = (path: string) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const newParams = new URLSearchParams();
  let hasMFESearchParams = false;
  params.forEach((value, key) => {
    if (
      Object.hasOwn ? Object.hasOwn(CONFIG, key) : CONFIG.hasOwnProperty(key)
    ) {
      hasMFESearchParams = true;
      newParams.append(key, value);
    }
  });
  return `${path}${
    hasMFESearchParams
      ? (path.includes('?') ? '&' : '?') + newParams.toString()
      : ''
  }`;
};

const useHandleCommandCreateLead = () => {
  const navigate = useNavigate();
  return useCallback(() => {
    navigate(getNewPath('/customers/create'));
  }, [navigate]);
};

const useHandleCommandUrlChange = () => {
  const navigate = useNavigate();
  return useCallback(
    (message: CommandUrlChange) => {
      if (
        message.payload.url.startsWith('/') ||
        message.payload.url.startsWith(window.location.origin)
      ) {
        const path = message.payload.url.replace(
          new RegExp(`^${window.location.origin}`),
          '',
        );
        navigate(getNewPath(path));
      } else {
        window.location.href = message.payload.url;
      }
    },
    [navigate],
  );
};

const useSendEventBootstrap = (
  ref: MutableRefObject<HTMLIFrameElement | null>,
  basePath: string,
) => {
  return useCallback(() => {
    postMessage(
      {
        type: 'EVENT:BOOTSTRAP',
        source: 'Container',
        payload: {
          baseUrl: `${window.location.origin}${basePath}`,
        },
      },
      ref,
    );
  }, [ref, basePath]);
};

const useSynchronizeUrl = (
  ref: MutableRefObject<HTMLIFrameElement | null>,
  src: string,
  basePath: string,
) => {
  const [staticSrc, setStaticSrc] = useState(src);
  useEffect(() => {
    if (!src.startsWith(basePath)) {
      setStaticSrc(src);
    } else if (ref.current) {
      postMessage(
        {
          type: 'COMMAND:CHANGE_URL',
          source: 'Container',
          payload: {
            url: src,
          },
        },
        ref,
      );
    }
  }, [basePath, ref, setStaticSrc, src]);
  return { staticSrc };
};

const useCommonMessageHandler = (
  ref: MutableRefObject<HTMLIFrameElement | null>,
  basePath: string,
) => {
  const handleCreateLead = useHandleCommandCreateLead();
  const handleUrlChange = useHandleCommandUrlChange();
  const sendEventBootstrap = useSendEventBootstrap(ref, basePath);
  const handler = useCallback(
    (message: Message | undefined): Message | undefined => {
      if (!message) {
        return;
      }
      switch (message.type) {
        case 'COMMAND:CREATE_LEAD':
          handleCreateLead();
          break;
        case 'COMMAND:CHANGE_URL':
          handleUrlChange(message);
          break;
        case 'COMMAND:LOGOUT':
          EVENT_BUS.emit('COMMAND:LOGOUT', message);
          break;
        case 'EVENT:AUTH_TOKEN':
          broadcastMessage(message);
          break;
        case 'EVENT:READY':
          sendEventBootstrap();
          break;
        case 'QUERY:AUTH_TOKEN':
          EVENT_BUS.emit('QUERY:AUTH_TOKEN', message);
          break;
        default:
          return message;
      }
    },
    [handleCreateLead, handleUrlChange, sendEventBootstrap],
  );
  return handler;
};

const useIframeMessages = (
  ref: MutableRefObject<HTMLIFrameElement | null>,
  source: Message['source'],
  basePath: string,
  localMessageHandler: (message: Message | undefined) => Message | undefined = (
    message,
  ) => message,
  options?: {
    eventTransformer?: (event: MessageEvent<any>) => MessageEvent<any>;
  },
) => {
  const commonMessageHandler = useCommonMessageHandler(ref, basePath);
  const messageHandler = useCallback<
    (event: MessageEvent<Message>) => Message | undefined
  >(
    (event) =>
      commonMessageHandler(
        localMessageHandler(
          unwrapAndFilterEvent(
            options?.eventTransformer ? options.eventTransformer(event) : event,
            source,
          ),
        ),
      ),
    [commonMessageHandler, localMessageHandler, options, source],
  );
  useEffect(() => {
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);
  const messageLoggerHandler = useCallback(
    (event: MessageEvent<Message>) => {
      const message = unwrapAndFilterEvent(event, source);
      if (!message) return;
      logMessage(message);
    },
    [source],
  );
  useEffect(() => {
    window.addEventListener('message', messageLoggerHandler);
    return () => {
      window.removeEventListener('message', messageLoggerHandler);
    };
  }, [messageLoggerHandler]);
  useEffect(() => {
    if (ref.current) {
      addSubscriber(ref);
      return () => {
        deleteSubscriber(ref);
      };
    }
  }, [ref]);
};

export {
  EVENT_BUS,
  getNewPath,
  unwrapAndFilterEvent,
  useIframeMessages,
  useSynchronizeUrl,
  useHandleCommandUrlChange,
};
