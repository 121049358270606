import { ComponentProps, useRef } from 'react';
import { useIframeMessages } from './common';
import { DashboardPage, getConfig } from './config';
import type { IFrame } from 'components/atoms/IFrame';

const useDashboardAdapter = (
  page: DashboardPage,
): ComponentProps<typeof IFrame> => {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const config = getConfig('dashboard', page);
  const src = `${config.iFrameUrl}`;
  useIframeMessages(ref, 'Dashboard', config.containerPath);
  return {
    ref,
    basePath: src,
    src,
    title: config.title,
  };
};

export { useDashboardAdapter };
