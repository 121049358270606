import { DashboardPage } from 'adapters/config';
import { useDashboardAdapter } from 'adapters/dashboard';
import { IFrame } from 'components/atoms/IFrame';

const DashboardIFrame = ({ page }: { page: DashboardPage }) => {
  const { basePath, ref, src, title } = useDashboardAdapter(page);
  return (
    <IFrame basePath={basePath} ref={ref} title={title} src={src}></IFrame>
  );
};

export { DashboardIFrame };
