import styled from '@emotion/styled/macro';
import { PropsWithChildren } from 'react';
import { useAuthAdapter } from 'adapters/auth';
import { IFrame } from 'components/atoms/IFrame';

const AuthUi = styled.div<{ showAuth: boolean }>`
  visibility: ${({ showAuth }) => (showAuth ? 'visible' : 'hidden')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const AuthProvider = ({ children }: PropsWithChildren) => {
  const { basePath, src, title, ref, showAuth, isLoaded } = useAuthAdapter();
  return (
    <>
      <AuthUi showAuth={showAuth}>
        <IFrame basePath={basePath} ref={ref} title={title} src={src}></IFrame>
      </AuthUi>
      {isLoaded && children}
    </>
  );
};

export { AuthProvider };
